
import { Vue, Component } from 'vue-property-decorator';
import MainHeader from '../../components/header/MainHeader.vue';
import Footer from '../../components/footer/Footer.vue';
import { dispatchGetCategoriesSubscriptionsIDList, dispatchGetProductsInWishlist } from '@/store/products/actions';
import { getLocalSessionId } from '@/utils';
import { readIsLoggedIn } from '@/store/main/getters';
import Cookies from '../../components/footer/Cookies.vue';

@Component({
  components: {
    MainHeader,
    Footer,
		Cookies
  },
})
export default class MainRoot extends Vue {

	public mounted() {
		dispatchGetProductsInWishlist(this.$store, {anon_session_id: getLocalSessionId()});
		const isLoggedIn = readIsLoggedIn(this.$store);
		if (isLoggedIn) {
			dispatchGetCategoriesSubscriptionsIDList(this.$store, {});
		}
	}
}
