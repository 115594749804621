
import { Component, Vue } from 'vue-property-decorator';
import { dispatchGetFooterMenuItemList } from '@/store/main/actions';
import { readFooterMenuItemList } from '@/store/main/getters';

@Component({
    components: {
    },
})

export default class Footer extends Vue {

  public async mounted() {
    await dispatchGetFooterMenuItemList(this.$store);
  }
  get footerMenuItemList() {
      return readFooterMenuItemList(this.$store);
  }
}
