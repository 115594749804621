
import { getLocalCookiesConfirm, saveLocalCookiesConfirm } from '@/utils';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})

export default class Cookies extends Vue {
  public showCookie = false;
  
  public mounted() {
    let confirmed = getLocalCookiesConfirm();
    this.showCookie = !confirmed;
  }

  public handleCloseCookies() {
    saveLocalCookiesConfirm('yes');
    this.showCookie = false
  }
}
